/**
 * Copyright © MageKey. All rights reserved.
 * See LICENSE.txt for license details.
 */
define('MageKey_SaveCart/js/view/popup',[
    'uiComponent',
    'jquery',
    'ko',
    'Magento_Customer/js/customer-data',
    'MageKey_SaveCart/js/model/popup',
    'MageKey_SaveCart/js/model/data',
    'mage/validation'
], function (Component, $, ko, customerData, cartPopup, cartData) {
    'use strict';

    return Component.extend({
        isLoading: cartData.isLoading,
        section: cartData.section,
        cartName: cartData.cartName,
        customerEmail: cartData.customerEmail,
        cartDetails: ko.observableArray([]),
        saveAction: null,
        showEmailField: ko.pureComputed(function () {
            return !cartData.isCustomer();
        }, this),

        /** Init save cart popup window */
        setModalElement: function (element) {
            if (cartPopup.modalWindow == null) {
                cartPopup.createPopUp(element);
            }
        },

        /** Save cart action */
        save: function (form) {
            var self = this;

            self.submitForm(form, function (response) {
                if (response.errors) {
                    cartData.messageContainer.addErrorMessage(response);
                } else {
                    cartData.messageContainer.addSuccessMessage(response);
                    if (response.email) {
                        cartData.customerEmail(response.email);
                    }
                    if (response.details) {
                        self.cartDetails(response.details);
                    }
                    if (response.reloadCart) {
                        customerData.reload(['cart'], false).always(function () {
                            if (window.mgkSaveCartConfig.isRefreshRequired) {
                                window.location.reload();
                            }
                        });
                    }
                    self.section('details');
                }
            });
            return false;
        },

        /** Submit form action */
        submitForm: function (form, callback) {
            var self = this,
                $form = $(form),
                formData = {},
                formDataArray = $form.serializeArray();
            formDataArray.forEach(function (entry) {
                formData[entry.name] = entry.value;
            });

            if ($form.validation() &&
                $form.validation('isValid')
            ) {
                self.isLoading(true);
                $.ajax({
                    url: $form.attr('action'),
                    type: 'POST',
                    data: formData,
                    dataType: 'json'
                }).done(function (response) {
                    callback(response);
                    self.isLoading(false);
                }).fail(function () {
                    cartData.messageContainer.addErrorMessage({'message': 'Could not save cart. Please try again later'});
                    self.isLoading(false);
                });
            }
        },

        closePopup: function () {
            cartPopup.closeModal();
        }
    });
});

