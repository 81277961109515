/**
 * Copyright © MageKey. All rights reserved.
 * See LICENSE.txt for license details.
 */
define('MageKey_SaveCart/js/view/button',[
    'uiComponent',
    'jquery',
    'ko',
    'MageKey_SaveCart/js/model/data',
    'MageKey_SaveCart/js/model/popup'
], function (Component, $, ko, cartData, cartPopup) {
    'use strict';

    return Component.extend({

        isButtonActive: cartData.isActive,

        /**
         * @override
         */
        initialize: function () {
            return this._super();
        },

        clickAction: function () {
            if (this.isButtonActive()) {
                cartPopup.showModal();
            }
        }
    });
});

