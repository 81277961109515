/**
 * Copyright © MageKey. All rights reserved.
 * See LICENSE.txt for license details.
 */
define('MageKey_SaveCart/js/model/data',[
    'jquery',
    'ko',
    'Magento_Customer/js/customer-data',
    'Magento_Ui/js/model/messageList',
    'jquery/jquery-storageapi'
], function ($, ko, customerData, messageContainer) {
    'use strict';

    return {
        isLoading: ko.observable(false),
        section: ko.observable('default'),
        cartName: ko.observable(''),
        messageContainer: messageContainer,

        isActive: ko.pureComputed(function () {
            if (!window.mgkSaveCartEnabled) {
                return false;
            }
            var cart = customerData.get('cart');
            return cart().summary_count;
        }, this),

        isCustomer: ko.pureComputed(function () {
            var customer = customerData.get('customer');
            return customer().firstname;
        }, this),

        customerEmail: ko.pureComputed({
            read: function () {
                return $.cookieStorage.get('mgk-savecart-email');
            },
            write: function (value) {
                $.cookieStorage.set('mgk-savecart-email', value)
            },
            owner: this
        }),

        createCartId: function () {
            var now = new Date();
            var cartId = now.getFullYear().toString();
            cartId += now.getMonth().toString();
            cartId += now.getDate().toString();
            cartId += now.getHours().toString();
            cartId += now.getMinutes().toString();
            cartId += now.getSeconds().toString();
            return cartId;
        },

        reset: function () {
            var self = this;
            self.messageContainer.clear();
            self.section('default');
            self.cartName(window.mgkSaveCartConfig.cartNameAutofill ? '#' + self.createCartId() : '');
        }
    }
});

