/**
 * Copyright © MageKey. All rights reserved.
 * See LICENSE.txt for license details.
 */
define('MageKey_SaveCart/js/model/popup',[
    'jquery',
    'Magento_Ui/js/modal/modal',
    'MageKey_SaveCart/js/model/data',
    'mage/translate'
],function ($, modal, cartData, $t) {
    'use strict';

    return {
        modalWindow: null,

        /** Create save cart popup window */
        createPopUp: function (element) {
            this.modalWindow = element;
            var options = {
                'title': $t('Save Cart'),
                'type': 'popup',
                'modalClass': 'magekey-savecart-popup',
                'responsive': true,
                'buttons': []
            };
            modal(options, $(this.modalWindow));
        },

        /** Show save cart popup window */
        showModal: function () {
            cartData.reset();
            $(this.modalWindow).modal('openModal');
        },

        /** Close save cart popup window */
        closeModal: function () {
            $(this.modalWindow).modal('closeModal');
        }
    }
});

